@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100vw;
  
  /*  background animation*/
  background: linear-gradient(to right top, #2e085f, #450a66, #ae5e5f);
  background-size: 150% 150%;
  -webkit-animation: bg-animation 18s ease-in-out infinite alternate;
  -moz-animation: bg-animation 18s ease-in-out infinite alternate;
  -o-animation: bg-animation 18s ease-in-out infinite alternate;
  animation: bg-animation 18s ease-in-out infinite alternate;
}

/* background animation */
@-webkit-keyframes bg-animation {
  0%{background-position:100% 0%}
  50%{background-position:50% 50%}
  100%{background-position:0% 100%}
}
@-moz-keyframes bg-animation {
  0%{background-position:100% 0%}
  50%{background-position:50% 50%}
  100%{background-position:0% 100%}
}
@-o-keyframes bg-animation {
  0%{background-position:100% 0%}
  50%{background-position:50% 50%}
  100%{background-position:0% 100%}
}
@keyframes bg-animation {
  0%{background-position:100% 0%}
  50%{background-position:50% 50%}
  100%{background-position:0% 100%}
}

.page-enter {
  opacity: 0;
  transform: scale(0.95);
}

.page-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 150ms, transform 150ms;
}

.page-exit {
  opacity: 1;
  transform: scale(1);
}

.page-exit-active {
  opacity: 0;
  transform: scale(0.95);
  transition: opacity 150ms, transform 150ms;
}